import { Link } from 'gatsby';
import React from 'react';
import VioletLogo from '../images/ndtservice-logo-violet.svg';
// import Logo from '../images/ndtservice-logo.svg';

const Header = () => (
  <nav>
    <Link to="/">
      <img src={VioletLogo} width="230px" alt="Logo NDT Service" />
    </Link>
    <ul className="navbar">
      <li>About us</li>
      <li>Services</li>
      <li>Certifications</li>
      <li>Work</li>
      <li>Contact</li>
    </ul>
  </nav>
);

export default Header;
